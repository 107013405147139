import React from "react";
import { Route, Routes } from "react-router-dom";
import Footer from "../repeated/footer";
import Header from "../repeated/header";
import Combined from "../components/combined";
import AncientAstronomy from "../components/AncientAstronomy.js";

const RouterFunction = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route exact path="/" element={<Combined />} />
        <Route path="/:slug1" element={<Combined />} />
        <Route path="/:slug1/:slug2" element={<Combined />} />
        <Route path="/:slug1/:slug2/:slug3" element={<Combined />} />
        <Route path="/:slug1/:slug2/:slug3/:slug4" element={<Combined />} />
      </Routes>
      <Footer />
    </>
  );
};

export default RouterFunction;
