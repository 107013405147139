import React from "react";
import { Link } from "react-router-dom";

const breadcrumb = (props) => {
  return (
    <>
      <section className="overview_section">
        <div className="row static_row m-0">
          <div className="col-12 map_col p-0">
            <img src={props?.data?.img} />
          </div>
        </div>
      </section>
      <section className="breadcrumb_section">
        <div className="d-flex justify-content-between">
          <div className="breadcrumb_links heading_family">
            <Link to="/">Home</Link>
            <div className="breadcrumb_dots1"></div>
            <div className="breadcrumb_dots2"></div>
            <div className="breadcrumb_dots3"></div>
            {props?.data?.breadcumbs}
          </div>
          <div className="breadcrumb_links heading_family">
            {props?.subBreadcrumbs?.map((item) => item?.title)?.join(" | ")}
          </div>
        </div>
      </section>
    </>
  );
};

export default breadcrumb;
